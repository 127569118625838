import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const {
    siteDesc,
    siteTitle,
    siteUrl,
    image,
    twitterUsername,
  } = site.siteMetadata


  return (
    <Helmet htmlAttributes={{lang:"en"}} title={`${siteTitle}`} >
          <meta name="description" content={description || siteDesc} />
          <meta name="image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={twitterUsername} />
          <meta name="twitter:title" content={siteTitle} />
          <meta name="twitter:description" content={siteDesc} />
          <meta name="twitter:image" content={`${siteUrl}${image}`} />

          <meta property="og:type" content="website"/>
          <meta property="og:url" content={siteUrl}/>
          <meta property="og:title" content={siteTitle}/>
          <meta property="og:description" content={description || siteDesc}/>
          <meta property="og:image" content={image} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
      </Helmet>
  ) 
}

export default SEO
